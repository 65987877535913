import { Alert, Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiCard from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import log from 'loglevel';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { GakakanIcon } from '../../assets/svg/GakakanIcon';
import { GoogleIcon } from '../../assets/svg/GoogleIcon';
import { RhfTextField } from '../../components/forms/RhfTextField';
import { BlankLayout } from '../../components/layout/BlankLayout';
import { useAppContext } from '../../contexts/AppContext';
import { useAuthContext } from '../../contexts/AuthContext';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  minHeight: '100%',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    zIndex: -1,
    inset: 0,
    backgroundImage:
      'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
    backgroundRepeat: 'no-repeat',
    ...theme.applyStyles('dark', {
      backgroundImage:
        'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
    }),
  },
}));

export function SignInPage() {
  const appContext = useAppContext();
  const authContext = useAuthContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isLocal, isProd, baseUrl, appBaseUrl } = appContext.env;
  const methods = useForm({ mode: 'onSubmit' });
  const [otpStep, setOtpStep] = React.useState(false);
  const [otpError, setOtpError] = React.useState('');

  const signInWithOtp = React.useCallback(async (d: any) => {
    log.debug('data', d);
    if (!otpStep && authContext.signInWithOtp) {
      const r = await authContext.signInWithOtp({ email: d.email });
      setOtpStep(true);
      log.debug('response', r);
    }
    if (otpStep && authContext.verifyOtp) {
      const r = await authContext.verifyOtp({
        email: d.email,
        token: d.otp,
      });
      if (!r.error) {
        const params = new URLSearchParams();
        params.set("auth", uuidv4());
        setSearchParams(params, { preventScrollReset: true });
      } else {
        setOtpError(r.error?.message ?? '');
      }
      log.debug('response', r);
    }
  }, [otpStep, authContext, setSearchParams]);

  const qparams = React.useMemo(() => {
    let redirectTo = `${isLocal ? appBaseUrl : baseUrl}/signin`;
    if (!isProd) {
      const r = decodeURIComponent(searchParams.get('redirectTo') || '');
      redirectTo = r || redirectTo;
    }

    let redirect = decodeURIComponent(searchParams.get('redirect') || '') || '/'
    try {
      const url = new URL(redirect);
      redirect = url.pathname + url.search;
    } catch (e: any) {
      log.trace('IGNORE: redirect url error', e.message);
    }

    return {
      error: searchParams.get('error'),
      error_code: searchParams.get('error_code'),
      error_description: searchParams.get('error_description'),
      redirect,
      auth: searchParams.get('auth'),
      force: searchParams.get('force'),
      redirectTo,
    }
  }, [searchParams, isLocal, isProd, baseUrl, appBaseUrl]);

  const signInWithGoogle = React.useCallback(async () => {
    if (!authContext.signInWithOAuth) {
      throw new Error('sign in not available');
    }
    const redirectTo = qparams.redirectTo
      + `?redirect=${encodeURIComponent(qparams.redirect)}`
      + `&auth=${uuidv4()}`;
    log.debug('redirect to', redirectTo);
    authContext.signInWithOAuth({
      provider: 'google',
      options: { redirectTo },
    });
  }, [qparams, authContext]);

  React.useEffect(() => {
    if (!authContext.initialized) return;
    if (authContext.user && qparams.force && authContext?.signOut) {
      authContext.signOut();
      return;
    }
    if (!qparams.auth) return;
    if (qparams.redirect) {
      window.location.replace(qparams.redirect);
    }
  }, [
    authContext,
    qparams.redirect,
    authContext.initialized,
    authContext.user,
    qparams,
  ]);

  return (
    <BlankLayout>
      <SignInContainer direction="column" justifyContent="space-between">
        <Card variant="outlined">
          <Stack direction={'column'} alignItems={'center'}>
            <GakakanIcon width='92px' />
            <Typography
              component="h1"
              variant="h4"
              sx={{ fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
            >
              Sign in
            </Typography>
          </Stack>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(signInWithOtp)}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <RhfTextField
                  name='email'
                  textFieldProps={{
                    type: 'email',
                    placeholder: 'Email',
                    required: true,
                  }}
                  useControllerProps={{
                    rules: { required: true }
                  }}
                />
                {otpStep ? (
                  <RhfTextField
                    name='otp'
                    textFieldProps={{
                      placeholder: 'One-Time Password',
                      slotProps: { htmlInput: { maxLength: 6 } },
                      required: otpStep,
                    }}
                    useControllerProps={{
                      rules: { required: otpStep }
                    }}
                  />
                ) : <></>}
                <Button
                  fullWidth
                  variant="outlined"
                  type='submit'
                >
                  {!otpStep ? 'Send One-Time Password' : 'Sign in'}
                </Button>
                {!otpError ? <></> : (
                  <Alert severity='error'>
                    {otpError}
                  </Alert>
                )}
              </Box>
            </form>
          </FormProvider>
          <Divider>or</Divider>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Button
              fullWidth
              variant="outlined"
              onClick={signInWithGoogle}
              startIcon={<GoogleIcon />}
            >
              Sign in with Google
            </Button>
            {!qparams.error_description ? <></> : (
              <Alert severity='error'>
                {qparams.error_description}
              </Alert>
            )}
          </Box>
        </Card>
      </SignInContainer>
    </BlankLayout>
  )
}
