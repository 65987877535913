import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { useController, UseControllerProps, useFormContext } from 'react-hook-form';

export interface RhfTextFieldProps {
  name: string;
  label?: string;
  textFieldProps?: TextFieldProps;
  defaultValue?: string;
  useControllerProps?: Omit<UseControllerProps, 'name' | 'control'>;
}

export function RhfTextField(props: RhfTextFieldProps) {
  const {
    name,
    label,
    textFieldProps,
    defaultValue = '',
    useControllerProps,
  } = props;

  const { control } = useFormContext();
  const { field } = useController({
    ...useControllerProps,
    name,
    control,
    defaultValue,
  });

  return (
    <TextField
      {...textFieldProps}
      slotProps={{
        inputLabel: { shrink: true },
        ...textFieldProps?.slotProps
      }}
      name={name}
      label={label}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
      inputRef={field.ref}
    />
  )
}
