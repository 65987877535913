import dayjs from 'dayjs';
import log from 'loglevel';
import React from 'react';
import { AuthContextProvider, AuthDataContext } from '../contexts/AuthContext';
import { useSupabase } from '../hooks/useSupabase';

export function Initialize(props: { children?: any }) {
  const supabase = useSupabase();
  const {
    signInWithOAuth,
    signInWithOtp,
    verifyOtp,
    signOut,
    refresh,
  } = supabase;

  const data = React.useMemo<AuthDataContext>(() => {
    const user = !supabase.user ? undefined : {
      fullName: '',
      email: supabase.user?.email || '',
      picture: supabase.user?.user_metadata?.picture || '',
    };

    return {
      initialized: supabase.initialized,
      user,
      accessToken: supabase.token,
      signInWithOAuth: (p) => signInWithOAuth(p),
      signInWithOtp: (p) => signInWithOtp(p),
      verifyOtp: (p) => verifyOtp(p),
      signOut: () => signOut(),
      refresh: () => refresh(),
    }
  }, [
    supabase.user,
    supabase.token,
    supabase.initialized,
    signInWithOAuth,
    signInWithOtp,
    verifyOtp,
    signOut,
    refresh,
  ]);

  React.useEffect(() => {
    if (supabase.initialized) return;
    const x = supabase.start();
    return () => x?.unsubscribe();
  }, [supabase.initialized, supabase]);

  React.useEffect(() => {
    if (!supabase.session?.expires_at) return;
    const expiresAtMs = supabase.session?.expires_at * 1000;
    const expiresAt = dayjs(expiresAtMs);
    const diffMs = expiresAtMs - 30000 - Date.now();
    log.debug('auth token expires at: ', expiresAt.toDate());
    log.debug('auth token next refresh at: ', dayjs(Date.now() + diffMs).toDate());
    const t = setTimeout(() => {
      refresh();
    }, diffMs);
    return () => {
      log.debug('timeout cleared.')
      clearTimeout(t);
    }
  }, [refresh, supabase.session]);

  log.debug('rerender Initialize')
  return (
    <AuthContextProvider data={data}>
      {supabase.initialized ? props.children : undefined}
    </AuthContextProvider>
  )
}
