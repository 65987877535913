import { AuthChangeEvent, AuthSession, AuthUser, createClient, SignInWithOAuthCredentials, SignInWithPasswordlessCredentials, SignOut, SupabaseClient, VerifyEmailOtpParams } from '@supabase/supabase-js';
import log from 'loglevel';
import React from 'react';

let sbclient: SupabaseClient;

function getClient() {
  if (sbclient) return sbclient;

  const {
    REACT_APP_SUPABASE_URL: supabaseUrl = '',
    REACT_APP_SUPABASE_ANON_KEY: supabaseAnonKey = '',
  } = process.env;

  sbclient = createClient(supabaseUrl, supabaseAnonKey, {
    auth: {
      autoRefreshToken: true,
    },
    realtime: {
      params: {
        eventsPerSecond: 10,
      },
    },
  });

  return sbclient;
}

export function useSupabase() {
  const [initialized, setInitialized] = React.useState(false);
  const [session, setSession] = React.useState<AuthSession>();
  const [user, setUser] = React.useState<AuthUser>();
  const [token, setToken] = React.useState<string | undefined>();

  const client = React.useMemo(() => getClient(), []);

  const signInWithOAuth = React.useCallback(async (credentials: SignInWithOAuthCredentials) => {
    if (!client) {
      throw new Error(`Auth client not started`);
    }
    return client.auth.signInWithOAuth(credentials);
  }, [client]);

  const signInWithOtp = React.useCallback(async (credentials: SignInWithPasswordlessCredentials) => {
    if (!client) {
      throw new Error(`Auth client not started`);
    }
    return client.auth.signInWithOtp({
      ...credentials,
      options: { shouldCreateUser: false },
    });
  }, [client]);

  const verifyOtp = React.useCallback(async (credentials: VerifyEmailOtpParams) => {
    if (!client) {
      throw new Error('');
    }
    const r = await client.auth.verifyOtp({
      ...credentials,
      type: 'email',
    });
    if (r.data.session) {
      await client.auth.setSession(r.data.session);
      setSession(r.data?.session ?? undefined);
      setUser(r.data?.user ?? undefined);
      setToken(r.data?.session?.access_token ?? undefined);
    }
    return r;
  }, [client]);

  const signOut = React.useCallback(async (options?: SignOut) => {
    if (!client) {
      throw new Error(`Auth client not started`);
    }
    setSession(undefined);
    setUser(undefined);
    setToken(undefined);
    return client.auth.signOut(options);
  }, [client]);

  const updateContext = React.useCallback((s: AuthSession | null) => {
    setSession(s ?? undefined);
    setUser(!s?.user ? undefined : s.user);
    setToken(s?.access_token ?? undefined);
  }, []);

  // Auth Listener
  const start = React.useCallback(() => {
    if (!client) return;

    log.debug(`supabase listener starting up`);
    const d = client.auth
      .onAuthStateChange((event: AuthChangeEvent, s: AuthSession | null) => {
        log.debug(`supabase auth event [${event}]:`, s);

        if (event === 'INITIAL_SESSION') {
          setInitialized(true);
          updateContext(s);
          return;
        }

        if (event === 'TOKEN_REFRESHED') {
          updateContext(s);
          return;
        }

        const sameToken = token === s?.access_token;
        if (sameToken) return;

        updateContext(s);
      });

    return {
      unsubscribe: () => {
        log.debug(`supabase listener unsubsribed`);
        d.data.subscription.unsubscribe();
      }
    };
  }, [client, updateContext, token]);

  const refresh = React.useCallback(() => {
    client.auth.refreshSession()
      .then((authResponse) => {
        log.debug('new session', authResponse.data.session);
        updateContext(authResponse.data.session);
      })
  }, [client, updateContext]);

  return {
    initialized,
    session,
    user,
    token,
    start,
    signInWithOAuth,
    signInWithOtp,
    verifyOtp,
    signOut,
    refresh,
  };
}